// import logo from './logo.svg';
// import './App.css';
import Hero from './component/HeroSection';

function App() {
  return (
    <>
    <Hero/>
    </>
  );
}

export default App;
